<template>
  <router-view />
</template>

<script>

export default {
};
</script>
<style lang="scss">
body {
  color: #000;
  background-color: #fff;

  .print-page .el-select {
    width: auto;
  }
}
</style>
